import React, { useEffect, useState } from "react";

import logo from "../../../img/logo.svg";
import { Button, Card, CardFooter, Col, Container, Row } from "react-bootstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import TextField from "../../components/TextField/TextField";
import { signIn } from "../../../redux/actions/auth.action";
import ContainerWrapper from "../../layouts/ContainerWrapper";

import { useQuery } from "../../../utils/core"

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const query = useQuery();


  const validateURL = async (value) => {
       await dispatch(signIn(value, navigate))
  }
  
  useEffect(() => {
    const params = query.get('prm');
    const txn_id = query.get('txn_id');
    
    validateURL({ params, txn_id });

  }, []); 

  return (
    <main className="main pt-0 bg-light">
    </main>
  );
};

export default Login;
