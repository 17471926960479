import {
  GET_ALL_CONFERENCE,
  GET_ALL_CONFERENCE_BY_FILTER,
  ADD_CONFERENCE,
  ATTEND_CONFERENCE,
  MY_CONFERENCE,
  GET_CONFERENCE_BY_ID,
  RESET_CONFERENE
} from "../constants/conferenceActionTypes";

const initialState = {
  conferenceList: [],
  attendConference: [],
  myConference: [],
  ConferenceById: "",
  isLoading: false,
  page: 1,
  hasMore: true,
};

export default (conference = initialState, action) => {
  switch (action.type) {
    case GET_ALL_CONFERENCE:
      return {
        ...conference,
        conferenceList: action.payload,
      };
    case GET_ALL_CONFERENCE_BY_FILTER:
      if (action.reset) {
        return {
          ...conference,
          conferenceList: action.payload,
          page: 1,
          hasMore: action.hasMore,
        };
      } else {
        return {
          ...conference,
          conferenceList: [...conference.conferenceList, ...action.payload],
          page: conference.page + 1,
          hasMore: action.hasMore,
        };
      }
    case ADD_CONFERENCE:
      return {
        ...conference,
        conferenceList: action.payload,
      };
    case ATTEND_CONFERENCE:
      return {
        ...conference,
        attendConference: action.payload,
      };
    case MY_CONFERENCE:
      return {
        ...conference,
        myConference: action.payload,
      };

    case GET_CONFERENCE_BY_ID:
      return {
        ...conference,
        ConferenceById: action.payload,
      };
    case RESET_CONFERENE:
      return {
        ...initialState
      };

    default:
      return conference;
  }
};
