import jwt_decode from "jwt-decode";
import { getCookie, clearCookie } from "../utils/core";
import * as api from "../services/api";


export const isLogin = async(setLoading) => {
  setLoading(true);
  try {
    const data = {
      token: JSON.parse(localStorage.getItem("token"))
    }
  
    const result = await api.validateURL(data);

    setLoading(false);

    if (result.data.data.valid) {
      return true;
    } else {
      localStorage.clear();
      console.log("invalidURL", result)
      // window.location.href = 'https://www.iacts.org/';
    }
  
  } catch (error) {
    setLoading(false);
    localStorage.clear();
    console.log("invalidURL catch", error)
    // window.location.href = 'https://www.iacts.org/';
  }
};

export const logOut = (navigate) => {
  //    const token = getCookie("token");
  // clearCookie("token");
  localStorage.clear();
  navigate("/login");
};
