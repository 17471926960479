import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Share from "../Share/Share";
import { read, utils } from "xlsx";
import { SendInvitation } from "../../../redux/actions/conference.action";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

const DetailsComponent = (props) => {

  const dispatch = useDispatch();
  const { id } = useParams();

  const location = useLocation();

  const handleView = () => {
    window.open(
      `https://d27m4xh8yh8co7.cloudfront.net/${process.env.REACT_APP_STAGE}${props.fileUrl}`
    );
  };


  const [file, setFile] = useState(null);
  const [fileError, setFileError] = useState(false);

  const handleFileInputChange = (event) => {
    // setFile(event.currentTarget.files[0]);

    const selectedFile = event.currentTarget.files[0];
    if (selectedFile) {

      const fileName = selectedFile.name;
      const fileExtension = fileName.split('.').pop().toLowerCase();

      if (fileExtension === 'xls' || fileExtension === 'xlsx' || fileExtension === 'csv') {
        setFileError(false);
      } else {
        setFileError(true);
        return;
      }

      const reader = new FileReader();
      reader.onload = (event) => {
        const fileData = event.target.result;
        const workbook = read(fileData, { type: "binary" });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = utils.sheet_to_json(worksheet, { header: 1 });

        // const firstColumnData = jsonData.map((row) => row[0].toString());
        const firstColumnData = jsonData.map((row) =>  row[0]);
        const result = firstColumnData.filter(res => res !== undefined && res !== null);

        let stringArray = result.map(item => item.toString());

        setFile(stringArray);
      };
      reader.readAsBinaryString(selectedFile);
    }
  };

  const sendBulkInvitations = () => {
      dispatch(SendInvitation({ id: id, invitation: JSON.stringify(file) }));
  }

  const copyLink = async () => {

    const text = `${props.module} Invitiation:\n\nTitle: ${props.title}\nDetails: ${props.detailsDescription}\n\nVenue: ${props.venue}\n\nHosted By: ${props.facultyName} \n\nURL: ${props.confUrl}`;

    // Create a temporary textarea element
    const textarea = document.createElement('textarea');
    textarea.value = text;

    // Hide the textarea
    textarea.style.position = 'fixed';
    textarea.style.opacity = 0;

    // Append the textarea to the body
    document.body.appendChild(textarea);

    // Select the text in the textarea
    textarea.select();

    try {
      // Copy the selected text to the clipboard
      document.execCommand('copy');
      console.log('Content copied to clipboard');
      alert('Content copied to clipboard. You can now paste it to share.');
    } catch (error) {
      console.error('Error copying to clipboard:', error);
    } finally {
      // Remove the textarea from the DOM
      document.body.removeChild(textarea);
    }

  };  

  return (
    <div class="container">
      <div class="row mt-2 justify-content-center">
        <div class="col-12 col-xl-9">
          <div class="bg-white card rounded-3 mb-4">
            {props.hideThumbNail ? null : (
              <div class="card-thumb-wrap card-thumb-lg">
                <img
                  src="https://dummyimage.com/16:9x1080"
                  class="card-img-top"
                  alt=""
                />
              </div>
            )}

            <div class="card-header bg-white p-4 pb-0 border-0">
              <div class="col-12">
                <div>
                  {/* <span class={`badge ${props.badgeClassName} mb-2`}>
                    {props.module}
                  </span> */}
                  <h2 class="h4 mb-3">{props.title}</h2>
                  <div class="event-quick-info-wrap">
                    {props.infoArray.map((val) => (
                      <p class="mb-2 d-block d-lg-inline-block me-0 me-lg-3 pe-0 pe-lg-3 border-end">
                        {val.name}: <strong>{val.value}</strong>
                      </p>
                    ))}
                  </div>
                  <hr />
                </div>
              </div>
            </div>
            <div class="card-body px-4 py-2">
              <h5>{props.detailsTitle}:</h5>
              <p>{props.detailsDescription}</p>
              {props.venue ? (
                <>
                  <h5 class="mt-4 mb-2">Venue:</h5>
                  <div class="author d-flex mb-2">
                    <span class="author-details">
                      <p class="fw-bold mb-0">{props.venue}</p>
                      <p class="mb-0">{props.facultyInstitution}</p>
                      <p class="mb-0">{props.location}</p>
                    </span>
                  </div>
                </>
              ) : null}
              {props.facultyName ? (
                <>
                  <h5 class="mt-4 mb-3">{props.facultyTitle}:</h5>
                  <div class="author d-flex mb-2">
                    <span class="user-image-wrap me-2">
                      {props.profilePicture ? (
                        <img
                          src={`https://d27m4xh8yh8co7.cloudfront.net/${process.env.REACT_APP_STAGE}${props.profilePicture}`}
                          alt=""
                          title="Surgeon Profile"
                        />
                      ) : (
                        <img
                          src={require("../../../img/user.png")}
                          alt=""
                          title="Surgeon Profile"
                        />
                      )}
                    </span>

                    <span class="author-details">
                      <Link to={`/surgeon-profile/${props.facultyId}`}>
                        <p class="fw-bold mb-0">{props.facultyName}</p>
                      </Link>
                      <p class="text-muted mb-0">{props.facultyInstitution}</p>
                    </span>
                  </div>
                </>
              ) : null}
            </div>

            <div class="card-footer bg-white p-4 pt-0 text-center border-0">
              <hr />
              {props.fee && props.module != "workshop" ? (
                <h5 class="mb-3">
                  Course Fee:{" "}
                  <span class="text-success">
                    {props.fee > 0 ? `Rs.${props.fee}` : "Free"}
                  </span>
                </h5>
              ) : null}

              {/* {props.buttonAction &&
              !props.hideActionButton &&
              !props.isHost &&
              !props.isVisible ? (
                <button
                  onClick={() => props.buttonAction(props.confUrl)}
                  class="btn btn-primary btn-lg mb-2"
                >
                  {props.buttonText} <i class="fa-solid fa-arrow-right"></i>
                </button>
              ) : null} */}
             
             {props.footerButton}

              {props.fileUrl ? (
                <a
                  href="javascript:void(0)"
                  onClick={() => {
                    handleView();
                  }}
                  class="btn btn-link mb-2"
                >
                  View Agenda/ Brochure <i class="fa-solid fa-arrow-right"></i>
                </a>
              ) : null}

               {/* <Share url={`${process.env.REACT_APP_URL}${location.pathname}`}/> */}

                <div class="m-2">
                  {props.module == "workshop" ? (
                    <button class="btn btn-primary m-2" data-toggle="collapse" href="#multiCollapseExample1" role="button" aria-expanded="false" aria-controls="multiCollapseExample1"><i class="fa-solid fa-user-group"></i> Bulk Invite</button>
                  ) : (null)}

                  <button class="btn btn-primary m-2" onClick={copyLink}><i class="fa-solid fa-copy"></i> Copy Link</button>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="collapse multi-collapse" id="multiCollapseExample1">
                      <div class="card card-body">
                        <div class="input-group mb-3">
                          <input 
                            type="file" 
                            name="bulkInvite" 
                            className="form-control"
                            onChange={handleFileInputChange}
                          />
                          <div class="input-group-append">
                            <span class="btn btn-primary" onClick={sendBulkInvitations}>Send</span>
                          </div>
                        </div>
                        {fileError ? (
                          <p style={{ color: '#f64e60', fontWeight: '600' }}>Only xls, xlsx, csv format is supported</p>
                        ) : (
                          null
                        )}
                      </div>
                    </div>
                  </div>
                </div>


            </div>
          </div>
        </div>
        {props.hideShareField ? null : (
          <div class="col-xl-3">
            <div class="bg-white card rounded-3 mb-4">
              <div class="card-body">
                <h5>Share {props.module}</h5>
                <p>Share the {props.module} with your friends and collegues.</p>
                <ul class="list-unstyled list-inline socials-list mb-0">
                  <li class="list-inline-item">
                    <a
                      href="https://facebook.com/surgicshare"
                      target="_blank"
                      title="Facebook"
                      class="text-primary fs-5"
                    >
                      <i class="fa-brands fa-facebook"></i>
                    </a>
                  </li>
                  <li class="list-inline-item">
                    <a
                      href="https://instagram.com/surgicshare"
                      target="_blank"
                      title="Instagram"
                      class="text-primary fs-5"
                    >
                      <i class="fa-brands fa-instagram"></i>
                    </a>
                  </li>
                  <li class="list-inline-item">
                    <a
                      href="https://www.linkedin.com/company/surgic-share/"
                      target="_blank"
                      title="Linkedin"
                      class="text-primary fs-5"
                    >
                      <i class="fa-brands fa-linkedin-in"></i>
                    </a>
                  </li>
                  <li class="list-inline-item">
                    <a
                      href="https://twitter.com/SurgicShare"
                      target="_blank"
                      title="Twitter"
                      class="text-primary fs-5"
                    >
                      <i class="fa-brands fa-twitter"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DetailsComponent;
