import React, {useState, useEffect} from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import CustomLoader from "../views/components/CustomLoader/CustomLoader";

// Import Utils
import { setTitle } from '../utils/core';

// Import Middleware
import { isLogin } from '../middleware/auth';

// Import public and private routes
import authRoutes from './authRouter';
import publicRoutes from './publicRouter';
import privateRoutes from './privateRouter';

// Import Components
import NotFound404 from '../views/pages/NotFound/NotFound404';

const getAuthRoutes = () => {
  
  return authRoutes.map((route) => {
    setTitle(route);
    return <Route key={route.path} exact path={route.path} element={route.component} />
  });

}

const getPublicRoutes = () => {

  return publicRoutes.map((route) => {
    setTitle(route);
  return <Route key={route.path} exact path={route.path} element={route.component} />
  });
  
};

const getPrivateRoutes = async (setLoading) => {

  if(await isLogin(setLoading)===true) {
    return privateRoutes.map((route) => {
      setTitle(route);
      return <Route key={route.path} exact path={route.path} element={route.component} />
    });
  } else {
     return authRoutes.map((route) => {
      setTitle(route);
      return <Route key={route.path} exact path={route.path} element={<Navigate to="/" />} />
    });
  }

};



const Approutes = ({ isLoggedIn }) => {
  const [privateRouteElements, setPrivateRouteElements] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchPrivateRoutes = async () => {
      if (isLoggedIn) {
        const routes = await getPrivateRoutes(setLoading);
        setPrivateRouteElements(routes);
      }
    };

    fetchPrivateRoutes();
  }, [isLoggedIn]);

  return (
    <BrowserRouter>
       <React.Fragment>
        {loading && <CustomLoader />}
        <Routes>
          {getAuthRoutes()}
          {getPublicRoutes()}
          {privateRouteElements}
          <Route path="*" element={<NotFound404 />} />
        </Routes>
      </React.Fragment>
    </BrowserRouter>
  );
};

export default Approutes;