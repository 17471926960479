import axios from 'axios';
import { getCookie, generateFingerprint } from "../../utils/core";

const API = axios.create({ baseURL: process.env.REACT_APP_API_URL });

API.interceptors.request.use( async (config) => {
      const token = JSON.parse(localStorage.getItem('token'));
      const txn_id = JSON.parse(localStorage.getItem('tnxId'));
      const fingerprint = await generateFingerprint();
      // console.log("fingerprint axios", fingerprint)
      const defaultData = {
        txn_id,
        fingerprint
      }

      // const token = getCookie("token");

      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }

      if (config.data) {
        config.data = {
          ...defaultData, 
          ...config.data
        };
      } else {
        config.data = defaultData;
      }


      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

export default API;