import React, { useEffect, useState } from "react";
import logo from "../../../img/logo.svg";
import { NavDropdown, Nav, Navbar } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import io from "socket.io-client";
import {
  getAllNotification,
  getNotification,
  markAsRead,
} from "../../../redux/actions/notification.action";
import { timeAgo } from "../../../utils/core";
import { SET_NEW_NOTIFICATION } from "../../../redux/constants/notificationTypes";
import ConfirmationPopup from "../Popup/ConfirmationPopup";
const TopNavBar = () => {
  const dispatch = useDispatch();
  const userProfile = useSelector((state) => state.auth.userProfile);
  const allNotifications = useSelector(
    (state) => state.notification.notifications
  );
  const mainNotifications = useSelector(
    (state) => state.notification.notificationList
  );
  const notificationCount = useSelector(
    (state) => state.notification.notificationCount
  );
  const [showPopup, setShowPopup] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const user = {
    user_id: userProfile?._id,
    name: userProfile?.firstName + " " + userProfile?.lastName,
  };

  // useEffect(() => {
  //   setNotifications(allNotifications.slice(0, 5));
  // }, [allNotifications]);
  useEffect(() => {
    setNotifications(mainNotifications);
  }, [mainNotifications]);

  useEffect(() => {
    // if (userProfile._id) {
    //   // const socket = io(`http://13.235.161.103:7000`);
    //   // const socket = io(`http://65.0.227.65:7000/notifications`);
    //   const socket = io(`https://api.surgicshare.com/notifications`);
    //   socket.emit("setup", user);

    //   socket.on("connect", () => {
    //     console.log("Connected to server", socket);
    //   });
    //   socket.on("newNotification", (data) => {
    //     console.log("New notification received:", data);
    //     dispatch({ type: SET_NEW_NOTIFICATION, payload: data });
    //   });

    //   return () => {
    //     socket.disconnect();
    //   };
    // }
  }, [userProfile]);


  useEffect(() => {
    window.scrollTo(0, 0);
    // dispatch(getAllNotification({ page: 1 }, true));
    dispatch(getNotification({}));
  }, []);

  let navigate = useNavigate();

  const handleNotificationClick = (val) => {
    dispatch(markAsRead({ id: val._id }));
    if (val.type === "VIDEOS") {
      navigate(`/video-details/${val.to_id}`);
    } else if (val.type === "TUTORIAL") {
      navigate(`/tutorial-details/${val.to_id}`);
    } else if (val.type === "CONFERENCE") {
      navigate(`/confDetails/${val.to_id}`);
    } else if (val.type === "MEETING") {
      navigate(`/cme-rte/${val.to_id}`);
    }
    window.location.reload();
  };


  const [isIconBurger, setIsIconBurger] = useState(true);

  const toggleIcon = () => {
    setIsIconBurger(!isIconBurger);
  };


  const visitWebsite = () => {
    window.location.href = `https://surgicshare.com`;
  }

  return (
    <header className="dash-header-wrap">
      {/* <Navbar
        collapseOnSelect
        expand="xl"
        className="fixed-top bg-white shadow"
      > */}
      <nav className="navbar navbar-expand-xl fixed-top bg-white shadow">
        <div className="container-fluid">


          <a href="javascript:history.back()" class="d-inline-block d-lg-none">
            <i class="fa fa-angle-left" style={{ fontSize: "20px", paddingRight: "16px"}} aria-hidden="true"></i>
            {/* <i class="fas fa-arrow-left"></i> */}
            {/* <span class="svg-icon svg-icon-primary svg-icon-2x">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">
                <g fill="none" fill-rule="evenodd">
                  <path d="M0 0h24v24H0z"/>
                  <path fill="#000" fill-rule="nonzero" d="M15.707 6.707a1 1 0 1 0-1.414-1.414l-6 6a1 1 0 0 0-.03 1.383l5.5 6a1 1 0 1 0 1.474-1.352l-4.853-5.294 5.323-5.323Z"/>
                </g>
              </svg>
            </span> */}
          </a>


          <a
            onClick={visitWebsite}
            className="navbar-brand"
            href="javascript:void(0)"
          >
            <img src={logo} alt="Logo"  style={{ width: "100px"}}  className="nav-logo"/>
          </a>
          {/* <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i className="ki-solid ki-burger-menu-5 text-primary"></i>
          </button> */}

          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={toggleIcon}
          >
            {isIconBurger ? (
              <i className="ki-solid ki-burger-menu-5 text-primary"></i>
            ) : (
              // <i className="ki-solid ki-close-circle text-primary"></i>
              <i class="fa fa-times" aria-hidden="true"></i>
            )}
          </button>

        

          <div
            className="collapse navbar-collapse pt-2 pt-xl-0"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav me-auto mb-2 mb-lg-0 ">
              {/* <Nav.Item className=" d-block d-xl-none d-xxl-block">
                <Nav.Link eventKey="3">
                  <Link to={"/register-now"}>Dashboard</Link>
                </Nav.Link>
              </Nav.Item> */}
              <Nav.Link eventKey="3">
                <Link to={"/register-now"} style={{fontSize: "95%"}}>Dashboard</Link>
              </Nav.Link>
              <Nav.Link eventKey="3">
                <Link to={"/register-now"} style={{fontSize: "95%"}}>CME</Link>
              </Nav.Link>
              <Nav.Link eventKey="3">
                <Link to={"/register-now"} style={{fontSize: "95%"}}>Surgery Videos</Link>
              </Nav.Link>
              <Nav.Link eventKey="3">
                <Link to={"/register-now"} style={{fontSize: "95%"}}>Tutorial</Link>
              </Nav.Link>
              <Nav.Link eventKey="3">
                <Link to={"/register-now"} style={{fontSize: "95%"}}>Observership</Link>
              </Nav.Link>
              <Nav.Link eventKey="3">
                <Link to={"/register-now"} style={{fontSize: "95%"}}>Conference</Link>
              </Nav.Link>
              <Nav.Link eventKey="3">
                <Link to={"/register-now"} style={{fontSize: "95%"}}>Patient Referral</Link>
              </Nav.Link>
              <Nav.Link eventKey="3">
                <Link to={"/register-now"} style={{fontSize: "95%"}}>Association & Groups</Link>
              </Nav.Link>
              <Nav.Link eventKey="3">
                <Link to={"/register-now"} style={{fontSize: "95%"}}>Telementorship</Link>
              </Nav.Link>
              {/* <Nav.Link eventKey="3">
                <Link to={"/support"} style={{fontSize: "95%"}}>Contact Us</Link>
              </Nav.Link> */}
            </ul>
          </div>
        </div>
      </nav>
      <ConfirmationPopup
        show={showPopup}
        message="Are you sure you want to log out? You can always log back in when needed."
        header="Confirm Logout ?"
        onConfirm={() => {
          localStorage.clear();
          navigate("/login");
        }}
        onCancel={() => {
          setShowPopup(false);
        }}
      />
    </header>
  );
};

export default TopNavBar;
