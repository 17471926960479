import React, { useEffect, useRef, useState } from "react";
import Root from "../../components/Root";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import ContainerWrapper from "../../layouts/ContainerWrapper";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import ThumbnailCard from "../../components/Cards/ThumbnailCard";
import Filters from "../../components/Filters/Filters";
import { useDispatch, useSelector } from "react-redux";

import { getAllMeetings } from "../../../redux/actions/meeting.action";
import { getLiveConference } from "../../../redux/actions/association.action";
import { useNavigate, Link } from "react-router-dom";
import NoDataFound from "../../components/NoDataFound/NoDataFound";
import InfiniteScroll from "../../components/InfiniteScroll/InfiniteScroll";
import { formatDate, formattTime, timeAgo, getStatusFromDate, isMeetingEnd } from "../../../utils/core";
import { SET_LOADING } from "../../../redux/constants/globalActionTypes";
import ProfileComplete from "../../components/CompleteProfile/ProfileComplete";

const LiveConference = () => {
  let navigate = useNavigate();
  const isInitialMount = useRef(true);

  const dispatch = useDispatch();
  const meetingsList = useSelector((state) => state.meeting.meetingsList);
  const pageNo = useSelector((state) => state.meeting.page);
  const hasMore = useSelector((state) => state.meeting.hasMore);

  const liveConferenceList = useSelector((state) => state.association.liveConference);

  const [currentUser, setCurrentUser] = useState(
    localStorage.getItem("userId")
  );

  useEffect(() => {
    dispatch({ type: SET_LOADING });
    const getConference = async () => {
      dispatch(getLiveConference({ page: 1  }, true));
    };

    getConference();
  }, []);

  return (
    <Root>
      <Breadcrumb title={"Live Conference"} fieldOne="Dashboard" fieldTwo="Live Conference" />
      <ContainerWrapper>
        {/* <ProfileComplete /> */}
        <div className="row mt-2">
          <div className="col-12 d-lg-flex justify-content-lg-between">
            <div>
              <h2 className="h4 mb-1">Live Conference</h2>
              <p>Total {liveConferenceList.length} Conference</p>
            </div>
            {/* <div className=" d-lg-block">
              <button
                title="Invitations"
                className="btn btn-primary mt-2 me-2"
                onClick={() => {
                  navigate("/cme-rtm-invitations");
                }}
              >
                Invitations <i className="fa-solid fa-arrow-right"></i>
              </button>
              <button
                title="Schedule CME (Continuing Medical Education)"
                className="btn btn-primary mt-2 me-2"
                onClick={() => {
                  navigate("/scheduleLiveCmeRtm");
                }}
              >
                Schedule CME (Continuing Medical Education) <i className="fa-solid fa-arrow-right"></i>
              </button>
              <button
                title="My Upload CME (Continuing Medical Education)"
                className="btn btn-primary mt-2"
                onClick={() => {
                  navigate("/myCmeRtm");
                }}
              >
                My Uploads <i className="fa-solid fa-arrow-right"></i>
              </button>
            </div> */}
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="bg-white card rounded-3 mb-4">
              {liveConferenceList.length > 0 ? (
                <div className="card-body px-4 py-3">
                  <div className="table-responsive">
                    <table className="table table-borderless table-striped table-lg align-middle cme-table pb-0">
                      <thead className="">
                        <th>Topic</th>
                        <th>Date &amp; Time</th>
                        <th>Hosted by</th>
                        <th>Status</th>
                        <th className="text-end">&nbsp;</th>
                      </thead>
                      <tbody>
                        {liveConferenceList.map((val, index) => (
                          <tr>
                            <td className="fw-bold">
                              <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                                Topic:
                              </p>
                              <h6 className="mb-0">{val.title}</h6>
                            </td>
                            <td>
                              <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                                Date &amp; Time:
                              </p>
                              <p className="mb-0">
                                {formatDate(val.startDate)}
                                <br />
                                {formattTime(val.startTime)} to{" "}
                                {formattTime(val.endTime)}
                              </p>
                            </td>
                            <td>
                              <p className="text-muted mb-0 fw-bold d-block d-lg-none mb-2">
                                Hosted by:
                              </p>
                              <div className="author d-flex">
                                <span className="user-image-wrap user-image-md me-md-3 mb-3 mb-md-0">
                                  <img
                                    src={require("../../../img/user.png")}
                                    alt=""
                                    title="Surgeon Profile"
                                  />
                                </span>
                                <span className="author-details">
                                  <p className="fw-bold mb-0">
                                    {/* <Link to={`/surgeon-profile/${val.user_details._id}`}> */}
                                      {val.venue}
                                    {/* </Link> */}
                                  </p>
                                  {/* <p className="text-muted mb-0">{`${val.user_details.institution.instituteName}, ${val.user_details.country.state.city.cityName}`}</p> */}
                                </span>
                              </div>
                            </td>
                            <td>
                              <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                                Status:
                              </p>
                              {(() => {
                                const dataResult = getStatusFromDate(val.startDate, val.endDate);
                                return <span className={`badge badge-${dataResult.color}`}>{`${dataResult.status}`}</span>;
                              })()}
                              
                            </td>
                            <td className="text-left text-lg-end">
                              {val.joinUrl != null && !isMeetingEnd(val.endDate) ? (
                                <a
                                  href={val.joinUrl}
                                  target="_blank"
                                  className=" btn btn-primary mb-2 btn-sm"
                                >
                                  Join{" "}
                                  <i className="fa-solid fa-arrow-right"></i>
                                </a>
                              ) : (
                                <span></span>
                              )}

                              {/* <button
                                onClick={() => {
                                  navigate(`/cme-rte/${val._id}`);
                                }}
                                className="mb-2 btn btn-link btn-sm"
                              >
                                Details{" "}
                                <i className="fa-solid fa-arrow-right"></i>
                              </button> */}
                            </td>
                          </tr>
                        ))}
                      
                      </tbody>
                    </table>
                  </div>
                </div>
              ) : (
                <NoDataFound />
              )}
            </div>
          </div>
        </div>
      </ContainerWrapper>
    </Root>
  );
};

export default LiveConference;
