import ConfirmPassword from '../views/pages/Auth/ConfirmPassword';
import ForgotPassword from '../views/pages/Auth/ForgotPassword';
import Login from '../views/pages/Auth/Login';
import Otp from '../views/pages/Auth/Otp';
import Register from '../views/pages/Auth/Register';
import LinkExpired from '../views/pages/Auth/LinkExpired';
import Signin from '../views/pages/Auth/Signin';
import OtpLogin from '../views/pages/Auth/OtpLogin';
import EnterLoginOTP from '../views/pages/Auth/EnterLoginOTP';

const authRoutes = [
    // {
    //     path: '/signin',
    //     component: <Signin />,
    //     title: "CRM Admin | Login"
    // },
    {
        path: '/',
        component: <Login />,
        title: "Surgic Share | Login"
    },
    {
        path: '/login',
        component: <Login />,
        title: "Surgic Share | Login"
    },
    {
        path: '/register',
        component: <Register />,
        title: "Surgic Share | Register"
    },
    {
        path: '/register-otp/:id',
        component: <Otp />,
        title: "Surgic Share | OTP"
    },
    {
        path: '/forgot-password',
        component: <ForgotPassword />,
        title: "Surgic Share | Forgot Password"
    },
    {
        path: '/reset-password',
        component: <ConfirmPassword />,
        title: "Surgic Share | Reset Password"
    },
    {
        path: '/link-expired',
        component: <LinkExpired />,
        title: "Surgic Share | Reset Password"
    },
    {
        path: '/otp-login',
        component: <OtpLogin />,
        title: "Surgic Share | OTP Login"
    },
    {
        path: '/login-otp/:id',
        component: <EnterLoginOTP />,
        title: "Surgic Share | OTP Login"
    }

];

export default authRoutes;