import moment from "moment";
import { AES, enc } from "crypto-js";
import CryptoJS from 'crypto-js';

// import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { useLocation } from "react-router-dom";

export const setTitle = (route) => {
    if(route.path === window.location.pathname) {
        document.title = route.title;
    } 
}


export const timeAgo = (timestamp) => {
     return moment(timestamp).fromNow(); 
}


export const formatDate = (timestamp) => {
    //  var date = moment(timestamp, moment.ISO_8601);
    // return date.format('D MMM YYYY');
    return moment(timestamp).format("D MMMM YYYY")
}

export const dateFormatt = (timestamp) => {
     var date = moment(timestamp, moment.ISO_8601);
    return date.format('D MMM YYYY');
    // return moment(timestamp).format("D MMMM YYYY")
}

export const dateformatter = (dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

export const getStatusFromDateString = (dateString) => {
  const currentDate = new Date();
  const targetDate = new Date(dateString);

  if (targetDate < currentDate) {
    return "COMPLETED";
  } else {
    return "UPCOMING";
  }
};

export const formattTime = (time) => {
    return moment(time, 'HH:mm').format('hh:mm A');
}

export const getTimeFromDate = (timestamp) => {
    const date = moment.utc(timestamp);
    return date.format('h:mm A');
}

export const getDate = (timestamp) => {
    return moment(timestamp).format("YYYY-MM-DD");
}


export const getStatusFromDate = (startDate, endDate) => {
  // const currentDate = new Date();
  const currentDate = currentDateAndTime();
  const start = new Date(startDate);
  const end = new Date(endDate);

  if (end < currentDate) {
    return {
      status: "COMPLETED",
      color: "success"
    };
  } else if (start < currentDate && end > currentDate) {
    return {
      status: "ON-GOING",
      color: "warning"
    };
  } else {
    return {
      status: "UPCOMING",
      color: "info"
    };
  }
};


export const isMeetingEnd = (endDate) => {

  const currentDate = currentDateAndTime();
  const end = new Date(endDate);

  if (end < currentDate) {
    return true
  } else {
    return false
  }
};

const currentDateAndTime = () => {

    let currentDate = new Date();

    currentDate.setHours(currentDate.getHours() + 5);
    currentDate.setMinutes(currentDate.getMinutes() + 30);
    return currentDate
}


export const changeRoute = (path) => {
    setTimeout(() => {
        window.location.href = path;
    }, 3000); 
}

// Function to encrypt data
export const encryptData = (data) => {
  const encrypted = AES.encrypt(JSON.stringify(data), "ABCD").toString();
  // Replace '+' and '/' characters with URL-safe characters
  const encoded = encrypted.replace(/\+/g, "-").replace(/\//g, "_");
  return encoded;
};

// Function to decrypt data
export const decryptData = (encodedData) => {
  // Restore '+' and '/' characters from URL-safe characters
  const encrypted = encodedData.replace(/-/g, "+").replace(/_/g, "/");
  const decrypted = AES.decrypt(encrypted, process.env.REACT_APP_AES_KEY).toString(enc.Utf8);
  return JSON.parse(decrypted);
};


export const encryption = (text, secretKey) => {
  return CryptoJS.AES.encrypt(text, secretKey).toString();
};

export const decryption = (cipherText, secretKey) => {
  try {
    console.log(cipherText)
    console.log(secretKey)
    const bytes = CryptoJS.AES.decrypt(cipherText, secretKey);
    console.log(bytes.toString(CryptoJS.enc.Utf8))
    return bytes.toString(CryptoJS.enc.Utf8);
  } catch (error) {
    return false;
  }
};

export const removeDuplicate = (inputArray) => {
  
  const uniqueArray = [];

  inputArray.forEach((obj) => {
    const exists = uniqueArray.some((uniqueObj) => uniqueObj._id === obj._id);
    if (!exists) {
      uniqueArray.push(obj);
    }
  });

  return uniqueArray;
}


export const setCookie = (key, value, expiresDays) => {
  const expires = new Date();
  expires.setDate(expires.getDate() + expiresDays);

  const cookieValue = `${encodeURIComponent(key)}=${encodeURIComponent(value)};expires=${expires.toUTCString()};path=/`;
  document.cookie = cookieValue;
}


export const getCookie = (key) => {
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split(';');

  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i].trim();
    if (cookie.startsWith(key + '=')) {
      return cookie.substring(key.length + 1); // Add 1 to skip the '=' character
    }
  }

  return null; // Return null if the cookie with the specified key is not found
}


export const clearCookie = (key) => {
   document.cookie = `${encodeURIComponent(key)}=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;`;
}


export const timeFormatt = (time) => {
    return moment(time, 'HH:mm').format('hh:mm A');
}

export const  digitToWord = (digit) => {
  const words = ['zero', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'];
  
  if (digit >= 0 && digit <= 9) {
    return words[digit];
  } else {
    return "Invalid input. Please provide a digit between 0 and 9.";
  }
}


// export const generateFingerprint = async () => {
//   // Initialize the FingerprintJS agent
//   // const fp = await FingerprintJS.load({extendedResult: true});

//   // // Get the visitor identifier
//   // const result = await fp.get();

//   // // Return the unique visitor ID
//   // return result.visitorId;
//   return null
// };

// export const generateFingerprint = async () => {
//   if (window.FingerprintJS) {
//     try {
//       const fp = await window.FingerprintJS.load();
//       const result = await fp.get();
//       console.log(result.visitorId)
//       return result.visitorId;
//     } catch (error) {
//       console.error('Error generating fingerprint:', error);
//       return null;
//     }
//   } else {
//     console.error('FingerprintJS is not available');
//     return null;
//   }
// };


export const generateFingerprint = async () => {
      const data = [
          navigator.userAgent,
          window.screen.width,
          window.screen.height,
          window.screen.colorDepth,
          navigator.language,
          navigator.platform,
          navigator.hardwareConcurrency,
          navigator.deviceMemory,
          navigator.cookieEnabled,
          navigator.doNotTrack,
          navigator.maxTouchPoints,
          navigator.vendor,
          Array.from(navigator.plugins)
              .map((plugin) => plugin.name)
              .join(","),
      ].join(" - ");
      const hashBuffer = await crypto.subtle.digest(
          "SHA-256",
          new TextEncoder().encode(data)
      );
      const hashArray = Array.from(new Uint8Array(hashBuffer));
      const fingerprint = hashArray.map((b) => b.toString(16).padStart(2, "0")).join("");
      return fingerprint;
  }

export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

