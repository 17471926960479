import { myAssociationConference } from "../actions/association.action";
import {
  ADD_ASSOCIATION,
  ADD_ASSOCIATION_UPDATE,
  DELETE_ASSOCIATION_UPDATE,
  EDIT_ASSOCIATION_UPDATE,
  GET_ALL_ASSOCIATION,
  GET_ALL_ASSOCIATION_BY_FILTER,
  GET_ALL_ASSOCIATION_BY_ID,
  GET_ALL_ASSOCIATION_CME,
  GET_ALL_ASSOCIATION_CONFERENCE,
  GET_ALL_ASSOCIATION_LIVESURGERYS,
  GET_ALL_ASSOCIATION_TUTORIAL,
  GET_ALL_ASSOCIATION_VIDEOS,
  GET_ASSOCIATION_DASHBOARD,
  GET_ASSOCIATION_DROPDOWN,
  GET_ASSOCIATION_TYPE,
  GET_ASSOCIATION_UPDATE,
  GET_MY_ASSOCIATION,
  JOIN_ASSOCIATION,
  LIST_ASSOCIATION_MEMBERS,
  MY_ASSOCIATION_CME,
  MY_ASSOCIATION_CONFERENCE,
  MY_ASSOCIATION_LIVESURGERYS,
  MY_ASSOCIATION_TUTORIAL,
  MY_ASSOCIATION_VIDEO,
  RESET_ASSOCIATION,
  SET_ASSOCIATION_EDIT_ID,
  GET_ASSOCIATION_LIVE_CONFERENCE
} from "../constants/assocGroupActionTypes";

const initialState = {
  surgeryList: [],
  mysurgeryList: [],

  videosList: [],
  myVideosList: [],

  tutorialsList: [],
  mytutorialsList: [],

  conferenceList: [],
  myConference: [],

  meetingsList: [],
  myMeetingLists: [],

  associationMembers: [],

  associationList: [],
  associationType: [],
  associationDropdown: [],
  myAssociation: [],
  associationUpdate: [],

  dashboardData:[],
  associationById: "",
  associationEditId: "",
  isLoading: false,
  
  liveConference:[],
  page: 1,
  hasMore: true,

  subPageNumber: 1,
  subHasMore: true,
};

export default (association = initialState, action) => {
  switch (action.type) {
    case GET_ALL_ASSOCIATION_BY_FILTER:
      if (action.reset) {
        return {
          ...association,
          associationList: action.payload,
          page: 1,
          hasMore: action.hasMore,
        };
      } else {
        return {
          ...association,
          associationList: [...association.associationList, ...action.payload],
          page: association.page + 1,
          hasMore: action.hasMore,
        };
      }
    case ADD_ASSOCIATION:
      return {
        ...association,
        associationList: action.payload,
        associationById: "",
      };
    case GET_ASSOCIATION_TYPE:
      return {
        ...association,
        associationType: action.payload,
      };
    case GET_ASSOCIATION_DROPDOWN:
      return {
        ...association,
        associationDropdown: action.payload,
      };
    case GET_MY_ASSOCIATION:
      return {
        ...association,
        myAssociation: action.payload,
      };
    case GET_ALL_ASSOCIATION_BY_ID:
      return {
        ...association,
        associationById: action.payload,
        associationEditId: "",
      };

    case JOIN_ASSOCIATION:
      const updatedData = association.associationList.map((val) =>
        val._id === action.payload._id ? action.payload : val
      );
      return {
        ...association,
        associationList: updatedData,
      };

    case GET_ALL_ASSOCIATION_LIVESURGERYS:
      if (action.reset) {
        return {
          ...association,
          surgeryList: action.payload,
          // page: 1,
          // hasMore: action.hasMore,
          subPageNumber: 1,
          subHasMore: action.hasMore,
        };
      } else {
        return {
          ...association,
          surgeryList: [...association.surgeryList, ...action.payload],
          // page: association.page + 1,
          // hasMore: action.hasMore,
          subPageNumber: association.page + 1,
          subHasMore: action.hasMore,
        };
      }

    case GET_ALL_ASSOCIATION_VIDEOS:
      if (action.reset) {
        return {
          ...association,
          videosList: action.payload,
          // page: 1,
          // hasMore: action.hasMore,

          subPageNumber: 1,
          subHasMore: action.hasMore,
        };
      } else {
        return {
          ...association,
          videosList: [...association.videosList, ...action.payload],
          // page: association.page + 1,
          // hasMore: action.hasMore,
          subPageNumber: association.page + 1,
          subHasMore: action.hasMore,
        };
      }

    case GET_ALL_ASSOCIATION_TUTORIAL:
      if (action.reset) {
        return {
          ...association,
          tutorialsList: action.payload,
          // page: 1,
          // hasMore: action.hasMore,
          subPageNumber: 1,
          subHasMore: action.hasMore,
        };
      } else {
        return {
          ...association,
          tutorialsList: [...association.tutorialsList, ...action.payload],
          // page: association.page + 1,
          // hasMore: action.hasMore,
          subPageNumber: association.page + 1,
          subHasMore: action.hasMore,
        };
      }

    case GET_ALL_ASSOCIATION_CONFERENCE:
      if (action.reset) {
        return {
          ...association,
          conferenceList: action.payload,
          // page: 1,
          // hasMore: action.hasMore,
          subPageNumber: 1,
          subHasMore: action.hasMore,
        };
      } else {
        return {
          ...association,
          conferenceList: [...association.conferenceList, ...action.payload],
          // page: association.page + 1,
          // hasMore: action.hasMore,
          subPageNumber: association.page + 1,
          subHasMore: action.hasMore,
        };
      }

    case GET_ALL_ASSOCIATION_CME:
      if (action.reset) {
        return {
          ...association,
          meetingsList: action.payload,
          // page: 1,
          // hasMore: action.hasMore,
          subPageNumber: 1,
          subHasMore: action.hasMore,
        };
      } else {
        return {
          ...association,
          meetingsList: [...association.meetingsList, ...action.payload],
          // page: association.page + 1,
          // hasMore: action.hasMore,
          subPageNumber: association.page + 1,
          subHasMore: action.hasMore,
        };
      }

    case MY_ASSOCIATION_LIVESURGERYS: //live surgery
      if (action.reset) {
        return {
          ...association,
          mysurgeryList: action.payload,
          subPageNumber: 1,
          subHasMore: action.hasMore,
        };
      } else {
        return {
          ...association,
          mysurgeryList: [...association.mysurgeryList, ...action.payload],
          subPageNumber: association.page + 1,
          subHasMore: action.hasMore,
        };
      }

    case MY_ASSOCIATION_VIDEO: //my surgetry vidoe
      return {
        ...association,
        myVideosList: action.payload,
        subPageNumber: 1,
        subHasMore: action.hasMore,
      };

    case MY_ASSOCIATION_TUTORIAL: // tutorial
      return {
        ...association,
        mytutorialsList: action.payload,
        subPageNumber: 1,
        subHasMore: action.hasMore,
      };

    case MY_ASSOCIATION_CME:
      if (action.reset) {
        return {
          ...association,
          myMeetingLists: action.payload,
          subPageNumber: 1,
          subHasMore: action.hasMore,
        };
      } else {
        return {
          ...association,
          myMeetingLists: [...association.myMeetingLists, ...action.payload],
          subPageNumber: association.page + 1,
          subHasMore: action.hasMore,
        };
      }

    case MY_ASSOCIATION_CONFERENCE:
      return {
        ...association,
        myConference: action.payload,
        subPageNumber: 1,
        subHasMore: action.hasMore,
      };

    case GET_ASSOCIATION_UPDATE:
      return {
        ...association,
        associationUpdate: [
          ...association.associationUpdate,
          ...action.payload,
        ],
        subPageNumber: association.page + 1,
        subHasMore: action.hasMore,
      };

    case ADD_ASSOCIATION_UPDATE:
      return {
        ...association,
        associationUpdate: [...association.associationUpdate, action.payload],
      };

    case EDIT_ASSOCIATION_UPDATE:
      let upDate = association.associationUpdate.map((val) =>
        val._id === action.payload._id ? action.payload : val
      );
      return {
        ...association,
        associationUpdate: upDate,
      };

    case DELETE_ASSOCIATION_UPDATE:
      return {
        ...association,
        associationUpdate: association.associationUpdate.filter(
          (val) => val._id !== action.payload.associationUpdateId
        ),
      };

    case LIST_ASSOCIATION_MEMBERS:
      return {
        ...association,
        associationMembers: [
          ...association.associationMembers,
          ...action.payload,
        ],
        subPageNumber: association.subPageNumber + 1,
        subHasMore: action.hasMore,
      };

    case GET_ASSOCIATION_DASHBOARD:
      return{
        ...association,
        dashboardData: action.payload
      };

    case GET_ASSOCIATION_LIVE_CONFERENCE:
      return{
        ...association,
        liveConference: action.payload
      };

    case RESET_ASSOCIATION:
      return {
        ...initialState,
      };
    default:
      return association;
  }
};
