import React, { useEffect, useState } from "react";

import AssociationLogo from "../../../img/association-default.png";
import surgeonPic from "../../../img/surgeon-image.png";
import dash1 from "../../../img/icon-live-surgery.png";
import dash2 from "../../../img/icon-meeting.png";
import dash3 from "../../../img/icon-video.png";
import dash4 from "../../../img/icon-tutorial.png";
import dash5 from "../../../img/icon-observership-2.png";
import dash6 from "../../../img/icon-workshop.png";
import DashboardTab from "../../components/Dashboard/Card";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";

import { Link } from "react-router-dom";
import Root from "../../components/Root";
import { Button, Col, Row, Card } from "react-bootstrap";
import ThumbnailCard from "../../components/Cards/ThumbnailCard";

import { useDispatch, useSelector } from "react-redux";
import { getAllDashboard } from "../../../redux/actions/dashboard.action";
import { useNavigate } from "react-router-dom";
import NoDataFound from "../../components/NoDataFound/NoDataFound";
import { timeAgo, formatDate, formattTime, getTimeFromDate,  getStatusFromDate} from "../../../utils/core";
import moment from "moment";
import ContainerWrapper from "../../layouts/ContainerWrapper";
import ProfileComplete from "../../components/CompleteProfile/ProfileComplete";

import HeroImage from "../../../img/hero.png"

const Dashboard = () => {
  let navigate = useNavigate();

  const dispatch = useDispatch();
  const dashboardList = useSelector((state) => state.dashboard.dashboardList);
  const pageNo = useSelector((state) => state.dashboard.page);

  const [currentUser, setCurrentUser] = useState(
    localStorage.getItem("userId")
  );

  useEffect(() => {
    dispatch(getAllDashboard({ page: 1 }, true));
  }, []);


  const registerConferenceNow = (url) => {
    window.open(url, '_blank');
    // dispatch(registerToConference({ id: id }));
  }

  return (
    <Root>
      {/* <ContainerWrapper>
        
      </ContainerWrapper>
   */}

         <div>
          <div className="b-example-divider"></div>

          <div className="px-4 pt-2 my-2 text-center border-bottom">
            <h1 className="display-4 fw-bold text-body-emphasis">Surgic Share</h1>
            <div className="col-lg-6 mx-auto">
              <p className="lead mb-4">
                Patented digital academic platform for expert, young and future surgeons for learning & sharing surgical skills. <br></br><br></br>

                <p> Download the app of <strong>"SURGIC SHARE"</strong> in Android and iOS</p>
              </p>
              <p><span style={{ fontWeight: "bold" }}>To access all these features, Please Register on "SURGIC SHARE"</span></p>
              <div className="d-grid gap-2 d-sm-flex justify-content-sm-center mb-5">
                <a href="https://surgicshare.com/register" target="_blank"><button type="button" className="btn btn-primary btn-lg px-4 me-sm-3">Register Now</button></a>
                <Link to={`/AssociationDetails/${process.env.REACT_APP_IACTS}`} className="btn btn-outline-secondary btn-lg px-4">Go Back</Link>
              </div>
            </div>
            <div className="overflow-hidden" style={{ maxHeight: '30vh' }}>
              <div className="container px-5">
                <img
                  src={HeroImage}
                  className="img-fluid border rounded-3 shadow-lg mb-4"
                  alt="Example image"
                  width="700"
                  height="500"
                  loading="lazy"
                />
              </div>
            </div>
          </div>

          <div className="b-example-divider"></div>
        </div>
    </Root>
  );
};

export default Dashboard;
