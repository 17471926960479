import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import 'bootstrap/dist/css/bootstrap.min.css';

// Import Routes
import Approutes from "./routes";

/*
 * Import Global Styles Sheets
 */
// Icons
import "./globalstyles/fonts/boxicons.css";
import "./globalstyles/fonts/fontawesome.css";
import "./globalstyles/fonts/flag-icons.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

// Core styles
import "./globalstyles/css/bootstrap.css";
import "./globalstyles/css/bootstrap.min.css";
import "./globalstyles/css/style.css";
import { useDispatch, useSelector } from "react-redux";
import { SET_USER_DATA } from "./redux/constants/authActionTypes";
import { SET_USER_PROFILE_STATUS } from "./redux/constants/globalActionTypes";


// // Vendors CSS
// import './globalstyles/libs/perfect-scrollbar/perfect-scrollbar.css';
// import './globalstyles/libs/typeahead-js/typeahead.css';

// // Vendor
// import './globalstyles/libs/formvalidation/dist/css/formValidation.min.css';

const App = () => {
  const userProfile = useSelector((state) => state.auth.userProfile);
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    if (localStorage.getItem("token")) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, []);


  // useEffect(() => {
  //   window.addEventListener("popstate", handleBackButtonClick);
  //   return () => {
  //     window.removeEventListener("popstate", handleBackButtonClick);
  //   };
  // }, []);

  // const handleBackButtonClick = () => {
  //   if (localStorage.getItem("token") === null) {
  //     // history.push("/login");
  //     window.location.href = "/login";
  //   }
  // };

  return (
    <>
      <Approutes isLoggedIn={isLoggedIn} />
      <ToastContainer />
    </>
  );
};

export default App;
