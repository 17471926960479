import Home from "../views/components/Home";
import About from "../views/pages/PublicScreens/About";
import Contact from "../views/pages/PublicScreens/Contact";
import Gallery from "../views/pages/PublicScreens/Gallery";
import GalleryDetails from "../views/pages/PublicScreens/GalleryDetails";
import Services from "../views/pages/PublicScreens/Services";
import Specialities from "../views/pages/PublicScreens/Specialities";

const publicRoutes = [
  // {
  //   path: "/",
  //   component: <Home />,
  //   title: "Surgic Share | Home",
  // },
  {
    path: "/home",
    component: <Home />,
    title: "Surgic Share | Home",
  },
  {
    path: "/speciality",
    component: <Specialities />,
    title: "Surgic Share | Home",
  },
  {
    path: "/services",
    component: <Services />,
    title: "Surgic Share | Home",
  },
  {
    path: "/gallery",
    component: <Gallery />,
    title: "Surgic Share | Home",
  },
  {
    path: "/about",
    component: <About />,
    title: "Surgic Share | Home",
  },
  {
    path: "/contact",
    component: <Contact />,
    title: "Surgic Share | Home",
  },
  {
    path: "/home/gallery-details",
    component: <GalleryDetails />,
    title: "Surgic Share | Home",
  }
];

export default publicRoutes;
